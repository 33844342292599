<template>
  <div>
  <div class="row">
    <div>
      <button type="button" id="join" @click="join">Join</button>
      <button type="button" id="leave" @click="leave">Leave</button>
    </div>
  </div>
  <br>
  <div id="message"></div>
  </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng"
import axios from "axios";
import firebase from "firebase";

export default {
  name: "CallPage",
  mounted() {
    this.startBasicCall();
  },
  data: function() {
    return {
      agoraEngine:null,
      options:
          {
            // Pass your App ID here.
            appId: '962b6a173ffd47f9a6cadd2ce2b08112',
            // Set the channel name.
            channel: 'AAAConnect',
            // Pass your temp token here.
            token: '',
            // Set the user ID.
            uid: 1,
            // Set token expire time.
            ExpireTime: 60,
            // The base URL to your token server. For example, https://agora-token-service-production-92ff.up.railway.app".
            serverUrl: 'https://xytq2ftmhznirrbu5zfnh3ygkq0jemnb.lambda-url.eu-west-1.on.aws'
          },
      channelParameters:
          {
            // A variable to hold a local audio track.
            localAudioTrack: null,
            // A variable to hold a remote audio track.
            remoteAudioTrack: null,
            // A variable to hold the remote user id.
            remoteUid: null,
          }
    }
  },
  methods: {
    // Fetches the <Vg k="VSDK" /> token
    async FetchToken()
    {
      let idToken = await firebase.auth().currentUser.getIdToken(true);
      const outerthis = this;
      return new Promise(function (resolve) {
        axios.get(outerthis.options.serverUrl+'/rtc/'+outerthis.options.channel+'/publisher/uid/'+outerthis.options.uid+'/?expiry='+ outerthis.options.ExpireTime, {
          headers: {
            Authorization: idToken
          }
        })
            .then(response => {
              console.log(response.data.rtcToken);
              resolve(response.data.rtcToken);
            })
            .catch(error => {
              console.log(error);
            });
      });
    },
    async join() {
      this.options.token=await this.FetchToken();
      await this.agoraEngine.join(this.options.appId, this.options.channel, this.options.token, this.options.uid);
      this.showMessage("Joined channel: " + this.options.channel);
      // Create a local audio track from the microphone audio.
      this.channelParameters.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Publish the local audio track in the channel.
      await this.agoraEngine.publish(this.channelParameters.localAudioTrack);
      console.log("Publish success!");
    },
    async leave() {
      // Destroy the local audio track.
      this.channelParameters.localAudioTrack.close();
      // Leave the channel
      await this.agoraEngine.leave();
      console.log("You left the channel");
      
    },
    startBasicCall() {
      // Create an instance of the Agora Engine
      this.agoraEngine = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});

      // Listen for the "user-published" event to retrieve an AgoraRTCRemoteUser object.
      this.agoraEngine.on("user-published", async (user, mediaType) => {
        // Subscribe to the remote user when the SDK triggers the "user-published" event.
        await this.agoraEngine.subscribe(user, mediaType);
        console.log("subscribe success");

        // Subscribe and play the remote audio track.
        if (mediaType === "audio") {
          this.channelParameters.remoteUid = user.uid;
          // Get the RemoteAudioTrack object from the AgoraRTCRemoteUser object.
          this.channelParameters.remoteAudioTrack = user.audioTrack;
          // Play the remote audio track. 
          this.channelParameters.remoteAudioTrack.play();
          this.showMessage("Remote user connected: " + user.uid);
        }

        // Listen for the "user-unpublished" event.
        this.agoraEngine.on("user-unpublished", user => {
          console.log(user.uid + "has left the channel");
          this.showMessage("Remote user has left the channel");
        });
        const outerthis = this;
        this.agoraEngine.on("token-privilege-will-expire", async function ()
        {
          this.options.token = await this.FetchToken();
          await outerthis.agoraEngine.renewToken(this.options.token);
        });
      });
    },
    showMessage(text) {
      document.getElementById("message").textContent = text;
    }
  }
}
</script>

<style scoped>

</style>